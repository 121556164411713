import * as React from 'react'
import Paper from '@material-ui/core/Paper'
import * as queryString from 'query-string'
import Polygon from './Polygon'
interface CreateProps {
  history: any
  match: any
  location: any
}

class Create extends React.Component<CreateProps> {
  public render() {
    const { user_id, client_id } = queryString.parse(this.props.location.search)
    return (
      <Polygon>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <img src="/habitat.png" style={{ height: 60 }} />
        </div>
        {user_id ? (
          <div>
            <div
              style={{
                padding: '10px 50px',
                fontSize: 20,
                textAlign: 'center',
                fontWeight: 300
              }}
            >
              Connect a Stripe account and start getting paid!
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 10
              }}
            >
              <Paper
                style={{
                  color: 'white',
                  background: '#556cd6',
                  cursor: 'pointer',
                  borderRadius: 4,
                  padding: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
                onClick={() => {
                  window.open(
                    `https://connect.stripe.com/express/oauth/authorize?client_id=${client_id}&state=${user_id}&stripe_user[business_type]=individual`
                  )
                }}
              >
                <div style={{ fontSize: 15, flexWrap: 'nowrap' }}>
                  Connect with
                </div>
                <img style={{ height: 22 }} src="/stripe2.png" />
              </Paper>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center', padding: 30 }}>
            Something went wrong. Please go back to the Habitat App and try
            again.
          </div>
        )}
      </Polygon>
    )
  }
}

export default Create
