import * as React from 'react'
import { hot } from 'react-hot-loader'
import Paper from '@material-ui/core/Paper'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import { Router, Route, Switch } from 'react-router-dom'
import Create from './Create'
import Success from './Success'
import createBrowserHistory from 'history/createBrowserHistory'

const history = createBrowserHistory()

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: {
    fontFamily: 'Roboto',
    useNextVariants: true
  }
});
class App extends React.Component {
  public render() {
    return (
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <Route
            render={({ location }) => {
              return (
                <Switch key={location.key} location={location}>
                  <Route exact path="/stripe" component={Create} />
                  <Route exact path="/stripe_success" component={Success} />
                </Switch>
              )
            }}
          />
        </MuiThemeProvider>
      </Router>

    )
  }
}

export default hot(module)(App)
