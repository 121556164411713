import * as React from 'react'
import Paper from '@material-ui/core/Paper'
import * as queryString from 'query-string'
import './main.css'
interface PolygonProps {
  children: any
}

const slanted = {
  WebkitClipPath: 'polygon(0 100%, 100% 100%, 100% 18%, 0 0)',
  clipPath: 'polygon(0 100%, 100% 100%, 100% 18%, 0 0)',
  background: '#afdbd4',
  height: '60vh',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  WebkitBoxshadow: '0px -7px 13px -6px rgba(0,0,0,0.75)',
  MozBoxShadow: '0px -7px 13px -6px rgba(0,0,0,0.75)',
  boxShadow: '0px -7px 13px -6px rgba(0,0,0,0.75)'
} as React.CSSProperties

const slanted_top = {
  WebkitClipPath: 'polygon(100% 0, 0 73%, 100% 100%)',
  clipPath: 'polygon(100% 0, 0 73%, 100% 100%)',
  background: '#c5e2d4',
  height: '80vh',
  position: 'absolute',
  top: 50,
  left: 0,
  right: 0,
  WebkitBoxshadow: '0px -7px 13px -6px rgba(0,0,0,0.75)',
  MozBoxShadow: '0px -7px 13px -6px rgba(0,0,0,0.75)',
  boxShadow: '0px -7px 13px -6px rgba(0,0,0,0.75)',
} as React.CSSProperties

const slanted_box = {
  WebkitClipPath: 'polygon(8% 6%, 91% 4%, 96% 95%, 4% 94%)',
  clipPath: 'polygon(8% 6%, 91% 4%, 96% 95%, 4% 94%)',
  background: 'white',
  WebkitBoxshadow: '0px -7px 13px -6px rgba(0,0,0,0.75)',
  MozBoxShadow: '0px -7px 13px -6px rgba(0,0,0,0.75)',
  boxShadow: '0px -7px 13px -6px rgba(0,0,0,0.75)',
  padding: '35px 0px',
  borderRadius: 6
} as React.CSSProperties


class Polygon extends React.Component<PolygonProps> {
  public render() {

    return (
      <div>
        <div style={{
          position: 'relative',
          zIndex: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
          filter: 'drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.2))'
        }}>
          <div style={slanted_box}>
            {this.props.children}
          </div>
        </div>
        <div style={slanted_top} />
        <div style={slanted} />
      </div>
    )
  }
}

export default Polygon
