import * as React from 'react'
import Paper from '@material-ui/core/Paper'
import * as queryString from 'query-string'
import './main.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Polygon from './Polygon'
interface SuccessProps {
  history: any
  match: any
  location: any
}

interface SuccessState {
  loading: boolean
  success: boolean
}

class Success extends React.Component<SuccessProps, SuccessState> {
  constructor(props: SuccessProps) {
    super(props)
    this.state = {
      loading: true,
      success: false
    }
  }
  public content() {
    return this.state.loading ? (
      <div
        style={{
          textAlign: 'center',
          padding: 20,
          fontSize: 18,
          fontWeight: 300
        }}
      >
        Loading...
      </div>
    ) : this.state.success ? (
      <div
        style={{
          textAlign: 'center',
          padding: 20,
          fontSize: 15,
          fontWeight: 300
        }}
      >
        Success! Head back to the First Delivery App to start earning!
        <Paper
          style={{
            color: 'white',
            background: '#556cd6',
            cursor: 'pointer',
            borderRadius: 4,
            padding: 10,
            display: 'flex',
            flexDirection: 'row'
          }}
          onClick={() => {
            window.open('first-delivery://')
          }}
        >
          <div style={{ fontSize: 17, flexWrap: 'nowrap' }}>Click Here</div>
        </Paper>
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            fontSize: 18,
            fontWeight: 300,
            textAlign: 'center',
            flexWrap: 'nowrap'
          }}
        >
          Something went wrong.
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
          <Paper
            style={{
              color: 'white',
              background: '#556cd6',
              cursor: 'pointer',
              borderRadius: 4,
              padding: 10,
              textAlign: 'center'
            }}
            onClick={() => {
              this.props.history.push('/stripe')
            }}
          >
            Try again
          </Paper>
        </div>
      </div>
    )
  }
  public render() {
    return (
      <Polygon>
        <div style={{ padding: 20 }}>{this.content()}</div>
      </Polygon>
    )
  }
  public async componentDidMount() {
    const { code, state } = queryString.parse(this.props.location.search)
    
    await axios
      .post(`${process.env.API_ROOT}/api/runners/activate_stripe`, {
        stripe_token: code,
        user_id: state
      })
      .then(res => {
        this.setState({ loading: false, success: true })
      })
      .catch(e => {
        this.setState({ loading: false })
      })
  }
}

export default Success
